import { UiService } from "@/services"
import NotificationStore from "@/store/notification"
import UiPageSessionStore from "@/store/ui-page-session"
import { useEffect, useState } from "react"

const useUiPageSession = ({ title, page }) => {
    const { showPage, flush, updateState, menuPage } = UiPageSessionStore()
    const { update } = NotificationStore()
    const [open, setOpen] = useState<boolean>(false)

    const handleDrawerClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        UiService.setState({ title })
        return () => {
            flush()
            if (menuPage !== page) {
                console.log("voantso")
                update({ show: false, message: "" })
            }
        }
    }, [flush, title])

    useEffect(() => {
        setOpen(showPage !== "list")
    }, [showPage])

    useEffect(() => {
        updateState({ menuPage: page })
    }, [])

    return {
        handleDrawerClose,
        open,
        showPage,
    }
}

export default useUiPageSession
